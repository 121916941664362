import React, { useState } from "react";
import { Flex, Box, Button } from "@chakra-ui/react";
import useSWR from "swr";
import fetcher from "@/utils/fetcher";
import useCustomToast from "hooks/useCustomToast";
import firebase from "firebase";
import Spinner from "@/components/Spinner";
import {sendEmail} from "@/lib/db";
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
const moment = require('moment-timezone');
const labelStyle = 'original'
const timezones = {
  ...allTimezones
}
const Payment = ({
  isDetail,
  setIsNext,
  selectedService,
  mentor,
  userData,
  sessionDate,
  sessionTime,
  card,
  session
}) => {
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const [isLoading,setIsLoading]=useState(false);
  const toast = useCustomToast();
  const { data } = useSWR(`/api/commissions/`, fetcher);
  const { data:settings,mutate} = useSWR(`/api/settings`, fetcher);
  const firestore = firebase.firestore();
  const db = firestore;
  const calculateStripeFee = (amount, feePercentage = 2.9, flatFee = 0.30) => {
    return flatFee + (amount * (feePercentage/100));
  };
  const getReminderTimeFrom12Hour = (date, startTimeStr, timezone) => {
    date=moment(date).format('YYYY-MM-DD');
    const [time, modifier] = startTimeStr.match(/(\d{1,2}:\d{2})(AM|PM)/).slice(1);
    let [hours, minutes] = time.split(':').map(Number);
    if (modifier === 'PM' && hours !== 12) hours += 12;
    if (modifier === 'AM' && hours === 12) hours = 0;
    const dateTimeStr = `${date} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    const mentorStartTime = moment.tz(dateTimeStr, 'YYYY-MM-DD HH:mm', timezone);
    if (!mentorStartTime.isValid()) {
        console.error('Invalid time or timezone:', dateTimeStr, timezone);
        return null;
    }
    const reminderTime = mentorStartTime.subtract(15, 'minutes');
    return reminderTime.utc().toISOString();
};
  const taxFee=6.25;
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setIsLoading(true);
    try {
      const res = await fetch('/api/stripe/create-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId: userData?.customerId,
          mentorStripeAccountId:mentor?.stripe_acc_id,
          amount:getDataPay('total'), 
          fee:getDataPay('fee'),
          tax: getDataPay('tax'),
          cardId:card?.id
        }),
      });

      const data = await res.json();
      if (data.error) {
        toast(data.error,'error','',true);
      } else {
        let prevSession=settings?.settings?.find(r=>r.name==="session_id") || null;
        prevSession={...prevSession,value:prevSession?.value+1};
        let rtime=await getReminderTimeFrom12Hour(sessionDate,sessionTime?.split('-')[0],mentor?.timezone);
        let d={
          sessionId:"ID0"+prevSession?.value,
          paymentIntent:data?.paymentIntent?.id,
          service:selectedService,
          userId:userData?.uid,
          mentor:mentor?.uid,
          sessionTime:sessionTime,
          sessionDate:sessionDate,
          reminderSessionDate:rtime,
          userTimezone:mentor?.timezone,
          userTimezoneOffset:options?.find(t=>t.value===mentor?.timezone)?.offset,
          amount:getDataPay('total'),
          fee:getDataPay('fee'),
          tax:getDataPay('tax'),
          status:'pending',
          createdAt:new Date(),
          updatedAt:new Date(),
        };
        await db.collection(`sessions`).add(d);
        await db.collection(`settings`).doc(prevSession?.id).update(prevSession);
        sendEmail('book-session',{email:mentor?.email,uemail:userData?.email,name:`${userData?.first_name} ${userData?.last_name || ''}`,mname:`${mentor?.first_name} ${mentor?.last_name || ''}`,date:sessionDate,time:sessionTime,link:`my-session/${"ID0"+prevSession?.value}`})
        setIsNext(4, ("ID0"+prevSession?.value));
        toast("Payment successful!", "success", '', true);
      }
    } catch (error) {
      console.log(error);
      
      toast('An error occurred while processing the payment.',"error",'',true);
    } finally{
      setIsLoading(false);
    } 
  };
  const getDataPay=(label)=>{
    const rate=Number(selectedService?.rate).toFixed(2);
    const fee=(rate*data?.commissions[0]?.platform_fee/100).toFixed(2);
    let tax=(rate*taxFee/100);
    tax=tax.toFixed(2);
    const total=((+rate)+(+fee)+(+tax)).toFixed(2);
    return (label==='rate')?rate:(label==='fee')?fee:(label==='tax')?tax:(label==='total')?total:'0.00';
  }
  return (
    <Flex w="100%" flexDirection="column" maxH="500px">
      {isLoading && <Spinner/>}
      <Box className="payment-section">
        <h3 className="heading-color pb-4 border-b border-[#194172]">
          Payment
        </h3>
        <Box className="py-[24px] border-b border-[#194172]">
          <Box className="grid grid-cols-6 mb-1">
            <p className="col-span-4">Service</p>
            <p className="col-span-2 text-left heading-color font-semibold">
              : ${getDataPay('rate')}
            </p>
          </Box>
          <Box className="grid grid-cols-6 mb-1">
            <p className="col-span-4">Platform Fee</p>
            <p className="col-span-2 text-left heading-color font-semibold">
              : ${isDetail?session?.fee:getDataPay('fee')}
            </p>
          </Box>
          <Box className="grid grid-cols-6">
            <p className="col-span-4 mb-0">Taxes</p>
            <p className="col-span-2 mb-0 text-left heading-color font-semibold">
              : ${isDetail?session?.tax:getDataPay('tax')}
            </p>
          </Box>
        </Box>
        <Box className="pt-4">
          <Box className="grid grid-cols-6">
            <p className="col-span-4 mb-0 text-green">Total</p>
            <p className="col-span-2 mb-0 text-left heading-color font-semibold text-green">
              ${isDetail?session?.amount:getDataPay('total')}
            </p>
          </Box>
        </Box>
        {!isDetail && (
          <Box className="flex flex-col gap-2 mt-4">
            <Button
              type="button"
              className="dark-blue-btn"
              onClick={(e) => handleSubmit(e)}
            >
              Confirm & Pay
            </Button>
            <Box className="text-center">
              <Button
                type="button"
                className="text-btn"
                onClick={(e) => setIsNext(2, null)}
              >
                Back
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      {!isDetail && (
        <Box className="mt-4">
          <p>
            A temporary hold equivalent to ${getDataPay('total')} may be placed on your
            payment method. You have the flexibility to cancel your appointment
            at any time. Please note that cancellations made within 24 hours of
            the scheduled start time may incur a 50% fee of the session rate.
            All sessions have a duration of one hour.
          </p>
        </Box>
      )}
    </Flex>
  );
};

export default Payment;