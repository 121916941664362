import React, { useState } from "react";

const ReadMoreLess = ({ description, descLength, extraClass }) => {
    const [length,setLength]=useState(descLength);
    return (<p className={extraClass}>{description?.slice(0,length)?.split('\n').map((line, index) => (
            <React.Fragment key={index}>
            {line}
            <br />
            </React.Fragment>))}
        {description?.length>=length && <>
          {description?.length>length?'...':''}
        {(description?.length>descLength) && (description?.length===length)?<button
            id="hide-btn"
            className="heading-color underline text-right w-full" onClick={()=>setLength(descLength)}
            >
            Read Less
            </button>
            :<button id="toggle-btn" className="heading-color underline text-right w-full" onClick={()=>setLength(description?.length)}>
            Read More
            </button>}
        </>}
      </p>
   );
  };
export default ReadMoreLess;