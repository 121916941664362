import React, { useEffect, useState } from "react";
import { Flex, Box,Button, useToast } from "@chakra-ui/react";
import { Calendar } from "react-multi-date-picker";
import { parse, format } from "date-fns";
import NextLink from "next/link";
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import DateObject from "react-date-object";
import useSWR from "swr";
import fetcher from "@/utils/fetcher";
import moment from "moment";
import {formattedDateSelected, formatTimeRangeToTimezone} from "@/lib/db";
import firebase from "firebase";
import useCustomToast from "hooks/useCustomToast";
import {sendEmail} from "@/lib/db";
const momentTime = require('moment-timezone');
const labelStyle = 'original'
const timezones = {
  ...allTimezones
}
const SelectDateTimeSessionModal = ({
  project,
  sessionData,
  isDetail,
  isNext,
  setIsNext,
  setselectedTime,
  editSession,
  sessionUser,
  userData
}) => {
  const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const normalizedTimezone = systemTimezone === 'Asia/Calcutta' ? 'Asia/Kolkata' : systemTimezone;
  const firestore = firebase.firestore();
  const db = firestore;
  const toast1 = useCustomToast();
  const { data } = useSWR(`/api/sessions`, fetcher);
  const [allHoldiays, setallHolidays] = useState([]);
  useEffect(() => {
    setallHolidays(project?.holidays);
  }, [project]);
  const toast = useToast();
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const today = new DateObject();
  const [availabilityMonth, setAvailabilityMonth] = useState(project?.mothlyAvailable);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timezone, setTimezone] = useState(project?.timezone || null);
  const [stimezone, setSTimezone] = useState(normalizedTimezone || null);
  const [selTime, setSelTime] = useState(null);
  const [times, setTimes] = useState([]);
  useEffect(() => {
    let dayName = new Date();
      dayName=dayName.toLocaleDateString("en-US", {
        weekday: "long",
      });
      notavil(new Date(),dayName);
  }, []);
  useEffect(() => {
    if (isNext) {
      document.getElementById("submitForm")?.click();
    }
  }, [isNext]);
  useEffect(()=>{
    if(sessionData || editSession){
      let d=editSession?{date:new Date(editSession?.sessionDate?.seconds * 1000 + editSession?.sessionDate?.nanoseconds / 1e6),time:editSession?.sessionTime}:sessionData;
      let dayName = new Date(d?.date)
      dayName=dayName.toLocaleDateString("en-US", {
        weekday: "long",
      });
      setSelTime(d.time);
      notavil(new Date(d?.date),dayName);
    }
  }, [sessionData,editSession]);

  const convertTo24Hour = (time) => {
    let [hour, modifier] = time.split(/(?=[APM])/);
    let [hours, minutes] = hour.split(":").map(Number);
    if (modifier === "P" && hours !== 12) {
      hours += 12;
    }
    if (modifier === "A" && hours === 12) {
      hours = 0;
    }
    const convertedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    return convertedTime;
  };
  const convertTo12Hour = (time) => {
    let [hours, minutes] = time.split(":").map(Number);
    const modifier = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format, ensuring 12 is handled correctly
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, '0')}${modifier}`;
  };

  const generateOneHourSlots12Hour = (range) => {
    const [rangeStart, rangeEnd] = range.split("-").map(convertTo24Hour);
    const [startHours, startMinutes] = rangeStart.split(':').map(Number);
    const [endHours, endMinutes] = rangeEnd.split(':').map(Number);
    const slots = [];
    let currentHours = startHours;
    let currentMinutes = startMinutes;
    let endHrs=(endHours===0 && range.split("-")[1]?.includes('AM'))?24:endHours
    while (
      (currentHours < endHrs) || 
      (currentHours === endHrs && currentMinutes < endMinutes)
    ) {
      let nextHours = currentHours;
      let nextMinutes = currentMinutes + 60;
      if (nextMinutes >= 60) {
        nextHours += 1;
        nextMinutes -= 60;
      }
      const startSlot = `${currentHours.toString().padStart(2, '0')}:${currentMinutes.toString().padStart(2, '0')}`;
      const endSlot = `${nextHours.toString().padStart(2, '0')}:${nextMinutes.toString().padStart(2, '0')}`;
      if (
        nextHours > endHrs || 
        (nextHours === endHrs && nextMinutes > endMinutes)
      ) {
        break;
      }
      const formattedStartSlot = convertTo12Hour(startSlot);
      const formattedEndSlot = convertTo12Hour(endSlot);
  
      slots.push(`${formattedStartSlot}-${formattedEndSlot}`);
      currentHours = nextHours;
      currentMinutes = nextMinutes;
    }
    return slots;
  };
  const getWeeklyTimes=(stimes)=>{
    if (!stimes) {
      return []; 
    }
    let allSlots = [];
    stimes.forEach((t) => {
      const slots = generateOneHourSlots12Hour(t); 
      allSlots = allSlots.concat(slots);
    });
    return [...new Set(allSlots)];
  }
  const checkAvailability = (slots, sesion) => {
    const availableTimes = getWeeklyTimes(slots);
    const availLength =(availableTimes.length>0)?(availableTimes?.filter(
      (f) => !sesion?.some((s) => f === s?.sessionTime)
    ).length > 0):false;
    return availLength;
  };
  const notavil = async (date, dayName) => {
    if (!date || date == null || date == undefined) return;
    let formattedDate = new Date(date);
    let mdate = moment(formattedDate).format("M/D/yyyy");
    let findMonthly = availabilityMonth?.find(
      (r) => moment(new Date(r.date)).format("M/D/yyyy") === mdate
    );
    let selDate = project?.availability.find((r) => r.day === dayName) || null;
    setSelectedDate(formattedDate);
    setselectedTime(formattedDate, 'date');
    let filteredTimes = [];
    if (findMonthly) {
      filteredTimes = await generateOneHourSlots12Hour(findMonthly?.time);
    } else if (selDate) {
      filteredTimes = await getWeeklyTimes(selDate?.times);
    }
    const dataSessions = data?.sessions?.filter(
      (res) =>
        res?.mentor === project?.id &&
        res?.status !== "cancelled" &&
        res?.status !== "completed"
    );
    dataSessions?.forEach((s) => {
      if (formattedDateSelected(s?.sessionDate) === mdate) {
        filteredTimes = filteredTimes.filter((f) => f !== s?.sessionTime);
      }
    });
    const currentDate = new Date();
    const currentTime = new Date(
        new Intl.DateTimeFormat('en-US', {
            timeZone: timezone,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        }).format(currentDate)
    ).getTime();
    const today = new Intl.DateTimeFormat('en-US', {
        timeZone: project?.timezone,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }).format(currentDate);
    const isToday = today === mdate;
    const fdate = moment(formattedDate)?.format("M/D/YYYY");
    const isWithinNextWeek = (findMonthly && filteredTimes.length>0)?true:!project?.weekly_repeat && project?.endofweek &&(new Date(fdate)<=new Date(formattedDateSelected(project?.endofweek)));
    const isNextMonth = (findMonthly && filteredTimes.length>0)?true:!project?.monthly_repeat && project?.endofmonth && (new Date(fdate)<=new Date(formattedDateSelected(project?.endofmonth)));
    const isAvailable =!findMonthly && (allHoldiays?.length > 0) &&  allHoldiays.find((availableDate) =>(mdate == moment(new Date(availableDate))?.format("M/D/yyyy")));
    const dataSessions1=data?.sessions?.filter(res=>(res?.mentor===project?.id) && (res?.status!=='cancelled') && (res?.status!=='completed'));
    let sesion=dataSessions1?.filter(s=>(formattedDateSelected(s?.sessionDate)==mdate));
    let isAvailableDay=(findMonthly && filteredTimes.length>0)?{times:filteredTimes}:project?.availability.find((avail) =>((avail?.day === dayName) && (avail?.times?.length>0)));
    let availTime=checkAvailability(isAvailableDay?.times, sesion);
    
    if ((!isWithinNextWeek && !project?.weekly_repeat) || (!isNextMonth && !project?.monthly_repeat) || isAvailable || !isAvailableDay || (isAvailableDay && (isAvailableDay !== undefined) && !availTime) || (isToday && (!isAvailableDay || (isAvailableDay && (isAvailableDay !== undefined) && !availTime)))) {
      filteredTimes = [];
    }else if(isToday){
      const threeHoursAhead = currentTime + 3 * 60 * 60 * 1000;
      filteredTimes = filteredTimes.filter((timeRange) => {
        const stimeRange=formatTimeRangeToTimezone(timeRange,project?.timezone,stimezone,new Date(formattedDate));
        const [startTime] = stimeRange.split("-");
        const timeString = `${mdate} ${startTime}`;
        const [datePart, timePart] = timeString.split(' ');
        const [month, day, year] = datePart.split('/');
        let [hour, minute] = timePart?.match(/(\d{1,2}):(\d{2})/).slice(1).map(Number);
        const modifier = timePart?.includes('PM') ? 'PM' : 'AM';
        if (modifier === 'PM' && hour !== 12) hour += 12;
        if (modifier === 'AM' && hour === 12) hour = 0;
        const timeDate = new Date(
            new Intl.DateTimeFormat('en-US', {
                timeZone: project?.timezone,
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false,
            }).format(new Date(year, month - 1, day, hour, minute))
        ).getTime();
        return timeDate > threeHoursAhead;
      });
    }
    
    await setTimes(filteredTimes);
  };
  const getReminderTimeFrom12Hour = (date, startTimeStr, timezone) => {
    date=momentTime(date).format('YYYY-MM-DD');
    const [time, modifier] = startTimeStr.match(/(\d{1,2}:\d{2})(AM|PM)/).slice(1);
    let [hours, minutes] = time.split(':').map(Number);
    if (modifier === 'PM' && hours !== 12) hours += 12;
    if (modifier === 'AM' && hours === 12) hours = 0;
    const dateTimeStr = `${date} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    const mentorStartTime = momentTime.tz(dateTimeStr, 'YYYY-MM-DD HH:mm', timezone);
    if (!mentorStartTime.isValid()) {
        return null;
    }
    const reminderTime = mentorStartTime.subtract(15, 'minutes');
    return reminderTime.utc().toISOString();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedDate) {
      setIsNext(1, null);
      toast({
        description: "Please select schedule date.",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    } else if (!selTime) {
      setIsNext(1, null);
      toast({
        description: "Please select schedule time.",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    } else if (!timezone) {
      setIsNext(1, null);
      toast({
        description: "Please select schedule timezone.",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    } else {
      const session = {
        date: selectedDate,
        time: selTime,
        timezone: timezone,
      };
      if (isDetail) {
        let rtime=await getReminderTimeFrom12Hour(new Date(selectedDate),selTime?.split('-')[0],project?.timezone);
        let updatedData = { ...editSession,mentee:null,mentorData:null, sessionDate: new Date(selectedDate), sessionTime: selTime,status:'rescheduled',rescheduleBy:userData?.role,reminderSessionDate:rtime,mentorTimezone:project?.timezone,
          mentorTimezoneOffset:options?.find(t=>t.value===project?.timezone)?.offset};
        await db.collection('sessions').doc(editSession?.id).update(updatedData);
        toast1("Successfully saved!", "success", '', true);
        sendEmail('reschedule-booking',
        {
          email:sessionUser?.email,
          uemail:userData?.email,
          name:`${userData?.first_name} ${userData?.last_name || ''}`,
          mname:`${sessionUser?.first_name} ${sessionUser?.last_name || ''}`,
          date:new Date(selectedDate),
          time:selTime,
          link:`my-session/${editSession?.sessionId}`
        });
        setIsNext(0, null);
      } else {
        setIsNext(2, session);
      }
    }
  };
  return (
    <Flex w="100%" flexDirection="column" bg="white" maxH="500px">
      <Box className="grid grid-cols-12">
        <Box className="session-calendar lg:col-span-7 col-span-12">
          <Calendar
            className="calendar"
            multiple={false}
            single
            value={selectedDate ? new DateObject(selectedDate) : null}
            minDate={today}
            format={"M/DD/YYYY"}
            onChange={(val) => {
              const selectedDate = new Date(val);
              const dayName = selectedDate.toLocaleDateString("en-US", {
                weekday: "long",
              });
              setSelectedDate(selectedDate);
              notavil(selectedDate, dayName);
              setSelTime(null);
              setselectedTime(null,'time');
            }}
            mapDays={({ date, selectedDate:selDateCal, currentMonth, isSameDate }) => {
              const currentDate = new Date();
              const dateObject = new Date(date.toString());
              const isPast = dateObject < currentDate?.setHours(0, 0, 0, 0);
              const fdate = date?.format("M/D/YYYY");
              const sellDate=selDateCal?.format("M/D/YYYY");
              const isSelected = fdate === sellDate;
              const jsDate = new Date(fdate);
              const dayName = jsDate.toLocaleDateString("en-US", {
                weekday: "long",
              });
              let mdate=moment(fdate)?.format("M/D/yyyy");
              let findMonthly = availabilityMonth?.find(
                (r) => moment(new Date(r.date)).format("M/D/yyyy") === mdate
              );
              const isWithinNextWeek = findMonthly?true:!project?.weekly_repeat && project?.endofweek &&(new Date(fdate)<=new Date(formattedDateSelected(project?.endofweek)));
              const isNextMonth = findMonthly?true: !project?.monthly_repeat && project?.endofmonth && (new Date(fdate)<=new Date(formattedDateSelected(project?.endofmonth)));
              const isAvailable =findMonthly && (allHoldiays?.length > 0) && allHoldiays.find((availableDate) =>(mdate == moment(new Date(availableDate))?.format("M/D/yyyy")));
              const dataSessions=data?.sessions?.filter(res=>(res?.mentor===project?.id) && (res?.status!=='cancelled') && (res?.status!=='completed'));
              let sesion=dataSessions?.filter(s=>(formattedDateSelected(s?.sessionDate)==mdate));
              let isAvailableDay=findMonthly?{times:generateOneHourSlots12Hour(findMonthly?.time)}:project?.availability.find((avail) =>((avail?.day === dayName) && (avail?.times?.length>0)));
              let availTime=checkAvailability(isAvailableDay?.times, sesion);
              const isToday=fdate === moment(new Date()).format("M/D/YYYY");
              if ((!isWithinNextWeek && !project?.weekly_repeat) || (!isNextMonth && !project?.monthly_repeat)
                  || isAvailable || !isAvailableDay
                  || (isAvailableDay && (isAvailableDay !== undefined) && !availTime) 
                  || isPast || (isToday && (!isAvailableDay || (isAvailableDay && (isAvailableDay !== undefined) && !availTime)
                ))
              ) {
                return {
                  disabled: true,
                  style: { backgroundColor: "#fff", color: "#333333" },
                };
              } else if(isSelected) {
                return {
                  style: { backgroundColor: "#194172", color: "#fff" },
                };
              }else if(isToday) {
                return {
                  style: { backgroundColor: "#DBEBFF", color: "#333333" },
                };
              }else{
                return {
                  style: { backgroundColor: "#DBEBFF", color: "#333333" },
                };
              }
            }}
          />
        </Box>
        <Box className="customer-reviews-wrapper time-span-select lg:col-span-5 col-span-12">
          {selectedDate && format(new Date(selectedDate), "EEEE, MMMM do")}
          {times?.length>0?<ul className="ps-0 gap-4 mb-4">
            {times?.map((item, i) => (
              <li key={i} onClick={() =>{ setSelTime(item);setselectedTime(item,'time');}}>
                <NextLink
                  href="javascript:void(0)"
                  className={`dark-blue-btn ${
                    selTime && selTime === item ? "active" : ""
                  }`}
                >
                  {formatTimeRangeToTimezone(item,project?.timezone,stimezone,new Date(selectedDate))}
                </NextLink>
              </li>
            ))}
          </ul>: <p>No available time slots</p>}
          <Box>
            <hr className="w-full mb-2"/>
          </Box>
          <Box className="timezone-inputs">
            <label htmlFor="inputname" className="form-label">
              Time Zone
            </label>
            <select
              className="form-select mt-2"
              defaultValue={stimezone}
              disabled
              onChange={(e) => setSTimezone(e.target.value)}
            >
              <option value="">Select</option>
              {options.map((option) => (
                <option
                  value={option.value}
                  selected={stimezone === option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </Box>
        </Box>
        <Box></Box>
        <Button
          id="submitForm"
          type="button"
          className="btn common-button"
          sx={{ display: "none" }}
          onClick={(e) => handleSubmit(e)}
        >
          Next
        </Button>
      </Box>
    </Flex>
  );
};

export default SelectDateTimeSessionModal;
