import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import { IoCard } from "react-icons/io5";

const CardDetail = ({ cardDetail,isChange,step,isDetail, setIsNext,changed }) => {
  const handleSubmit = async (event) => {
      event.preventDefault();
      setIsNext(2,null); 
  };
  return (
    <Flex w="100%" flexDirection="column" maxH="500px">
      <Box className={`${!isDetail?'card-detail-section':''} flex justify-between items-center mt-5`}>
        <Box className="flex gap-3 items-center">
          {step===2 && <input
            type="checkbox"
            defaultChecked={isChange}
            onChange={()=>changed()}
          />}
          <IoCard className="heading-color text-[40px]"></IoCard>
          <Box>
            <h5 className="heading-color">Card Detail</h5>
            <p>{cardDetail?.brand} (**** {cardDetail?.last4})</p>
          </Box>
        </Box>
        {!isDetail && (step!==2) && <Box>
          <span type="button" className="heading-color font-bold  underline cursor-pointer" onClick={(e)=>handleSubmit(e)}>
            Change
          </span>
        </Box>}
      </Box>
    </Flex>
  );
};

export default CardDetail;