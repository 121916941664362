import React from "react";
import { Flex, Box, Img } from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { GoClockFill } from "react-icons/go";
import NextLink from "next/link";
import { format } from "date-fns";
import { useAuth } from "@/lib/auth";
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import { formatTimeRangeToTimezone } from "@/lib/db";
const moment = require('moment-timezone');
const labelStyle = 'original'
const timezones = {
  ...allTimezones
}
const MentorAndSession = ({
  project,
  isThanks,
  selectedService,
  sessionDate,
  sessionTime,
  sessionTimezone,
  sessionId,
  userData
}) => {
  const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const normalizedTimezone = systemTimezone === 'Asia/Calcutta' ? 'Asia/Kolkata' : systemTimezone;
  const {user} = useAuth();
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const getFormattedYear = (date) => {
    if (!date) return;
    let formattedYear = new Date(date?.seconds * 1000).getFullYear();
    return formattedYear;
  };
  const getReminderTimeFrom12Hour = (date, startTimeStr, timezone) => {
    date=moment(date).format('YYYY-MM-DD');
    const [time, modifier] = startTimeStr.match(/(\d{1,2}:\d{2})(AM|PM)/).slice(1);
    let [hours, minutes] = time.split(':').map(Number);
    if (modifier === 'PM' && hours !== 12) hours += 12;
    if (modifier === 'AM' && hours === 12) hours = 0;
    const dateTimeStr = `${date} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    const mentorStartTime = moment.tz(dateTimeStr, 'YYYY-MM-DD HH:mm', timezone);
    if (!mentorStartTime.isValid()) {
        return null;
    }
    return mentorStartTime.utc().toISOString();
  };
 const getFormatDayAndDate = (date) => {
   if (!date) return;
   date=getReminderTimeFrom12Hour(date,sessionTime?.split('-')[0],sessionTimezone);
   date=new Date(date);
   const dayName = format(date, "EEEE");
   const day = format(date, "d"); 
   const month = format(date, "MMMM"); 
   return `${dayName}, ${day} ${month}`; 
 };
  return (
    <Flex w="100%" flexDirection="column" maxH="500px">
      <Box className="grid grid-cols-12">
        <Box
          className={`${
            isThanks ? "md:col-span-4" : "md:col-span-12"
          } col-span-12 flex items-center gap-4 md:mt-[20px] mt-0`}
        >
          <Box className="w-[100px] h-[100px] rounded-full my-profile-img">
            <Img
              className="w-[100%] h-[100%] object-cover rounded-full"
              src={project?.profile_pic?project?.profile_pic:'/assets/no_profile.jpg'}
              alt="Profile Image"
            />
          </Box>
          <Box className="mentor-details">
            <p className="heading-color font-bold mb-2 capitalize">{project?.first_name+ (project?.last_name?' '+project?.last_name:'')}</p>
            <p className="light-black mb-2 capitalize">{project?.jobs && project?.jobs[0]?.title}</p>
            {(userData?.role==='mentee') && <Box className="flex gap-2 items-center mb-2">
              {project?.is_linkedin_verified && (
                <>
                  <FaCheckCircle className="text-green"></FaCheckCircle>
                  <p className="heading-color mb-0">Verified</p>
                </>
              )}
            </Box>}
            <p className="light-black mb-1">
              {(userData?.role==='mentee')?'Mentor':'Mentee'} since {getFormattedYear(project?.created_at)}
            </p>
            {/* <NextLink href={`/my-session/${sessionId}`} className="underline heading-color">
              {sessionId}
            </NextLink> */}
          </Box>
        </Box>
        <Box
          className={`${
            isThanks ? "md:col-span-4 thanks-session mb-4" : "md:col-span-6"
          } col-span-12 lg:mt-[30px] mt-[15px]`}
        >
          <p className="heading-color font-bold">Session Date and Time</p>
          <Box className="flex gap-1 mb-2">
            <GoClockFill className="gray-color clock-icon"></GoClockFill>
            <Box>
              <p className="mb-0">
                {
                  getFormatDayAndDate(sessionDate)
                }
              </p>
              <p className="mb-2">{sessionTime && formatTimeRangeToTimezone(sessionTime,sessionTimezone,normalizedTimezone,sessionDate)}</p>
              <p>{options.find(t=>t.value===normalizedTimezone)?.label}</p>
            </Box>
          </Box>
        </Box>
        <Box
          className={`${
            isThanks ? "md:col-span-4 mb-4" : "md:col-span-6"
          } col-span-12 lg:mt-[30px] mt-[15px]`}
        >
          <p className="heading-color font-bold">{selectedService?.name}</p>
          <p>
            Total Amount:{" "}
            <span className="text-black">${selectedService?.rate}/hr</span>
          </p>
        </Box>
      </Box>
    </Flex>
  );
};

export default MentorAndSession;
