import React from "react";
import { Flex, Box} from "@chakra-ui/react";
import MentorAndSession from "./MentorAndSession";
import Payment from "./payment";
import CardDetail from "./CardDetail";
const SessionInvoiceModal = ({
  project,
  isDetail,
  setIsNext,
  selectedService,
  sessionTime,
  sessionDate,
  sessionTimezone,
  cardDetail,
  userData,
  session
}) => {
  return (
    <Flex w="100%" flexDirection="column" maxH="500px">
      <Box className="grid grid-cols-12 gap-4 mt-5">
        <Box className="lg:col-span-7 col-span-12">
          <Box>
            {!isDetail && <h3 className="heading-color mb-3">Mentor Details</h3>}
            {/* <hr/> */}
            <MentorAndSession
              isDetail={isDetail}
              project={project}
              selectedService={selectedService}
              sessionTime={sessionTime}
              sessionDate={sessionDate}
              sessionTimezone={sessionTimezone}
              sessionId={session?.sessionId}
              userData={userData}
            />
          </Box>
  
          {cardDetail && <Box className="lg:col-span-6 col-span-12">
            <CardDetail cardDetail={cardDetail} isDetail={isDetail} setIsNext={setIsNext} />
          </Box>}
        </Box>
        <Box className="lg:col-span-5 col-span-12">
          <Payment
            isDetail={isDetail}
            setIsNext={setIsNext}
            selectedService={selectedService}
            mentor={project}
            userData={userData}
            sessionTime={sessionTime}
            sessionDate={sessionDate}
            card={cardDetail}
            session={session}
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default SessionInvoiceModal; 
